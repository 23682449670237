<template>
  <div>
    <div v-if="CAN_VIEW">
      <v-row>
        <v-col cols="12" sm="10" class="mb-12 mx-auto">
          <v-card class="mb-12" flat>

            <v-card-title>
              <v-row dense class="border-bottom">

                <v-col cols="12" sm="8" class="text-center">
                  <v-avatar size="80" rounded="xl">
                    <v-img
                      :src="`${$store.state.user.current_business.logo}`"
                      alt="logo"
                      contain
                    >
                    </v-img>
                  </v-avatar>

                  <h2 class="text-primary text-uppercase mt-3">
                    {{ $store.state.user.current_business.name }}
                  </h2>
                  <h4>Income Statement</h4>

                  <h4>
                    <strong>Period:</strong>
                    {{ startdate | humanDate }}
                    To {{ enddate | humanDate }}
                  </h4>

                  <h4 v-if="compareOn && second_income_statement">
                    <strong>Compared with:</strong>
                    {{ secondStartdate | humanDate }}
                    To
                    {{ secondEnddate | humanDate }}
                  </h4>
                </v-col>
                <v-col cols="12" sm="4">
                  <h3 v-if="compareOn" class="font-weight-bold">Period 1</h3>

                  <v-row dense>
                    <v-col cols="6" sm="6">
                      <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="startdate"
                            label="From"
                            persistent-hint
                            flat
                            filled
                            rounded
                            color="orange"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @change="get_incomestatement"
                          v-model="startdate"
                          no-title
                          @input="menu1 = false"
                          color="orange"

                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="6" sm="6">
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            persistent-hint
                            readonly
                            flat
                            filled
                            color="orange"
                            rounded
                            v-on="on"
                            label="To"
                            v-model="enddate"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @change="get_incomestatement"
                          v-model="enddate"
                          no-title
                          @input="menu2 = false"
                          color="orange"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" class="text-center">
                      <v-btn
                        @click="
                          compareOn = true;
                          get_secondIncomestatement();
                        "
                        v-if="!compareOn"
                        small
                        rounded
                        text
                        color="orange"
                      >Compared to
                        <v-icon small>mdi-swap-vertical</v-icon>
                      </v-btn>
                      <v-btn
                        @click="compareOn = false"
                        v-if="compareOn"
                        small
                        rounded
                        text
                        color="error"
                        >Cancel
                        <v-icon small>mdi-close</v-icon>
                      </v-btn>
                    </v-col>

                    <v-col cols="12" sm="12" v-if="compareOn">
                      <h3 v-if="compareOn" class="font-weight-bold">
                        Period 2
                      </h3>
                    </v-col>

                    <v-col cols="6" sm="6" v-if="compareOn">
                      <v-menu
                        ref="menu1"
                        v-model="secondMenu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="secondStartdate"
                            label="From"
                            persistent-hint
                            flat
                            filled
                            rounded
                            color="orange"

                            v-on="on"
                            :disabled="secondProgress"
                            :loading="secondProgress"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @change="get_secondIncomestatement"
                          v-model="secondStartdate"
                          no-title
                          color="orange"

                          @input="secondMenu1 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="6" sm="6" v-if="compareOn">
                      <v-menu
                        v-model="secondMenu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            persistent-hint
                            readonly
                            flat
                            filled
                            rounded
                            v-on="on"
                            color="orange"

                            label="To"
                            :loading="secondProgress"
                            :disabled="secondProgress"
                            v-model="secondEnddate"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @change="get_secondIncomestatement"
                          v-model="secondEnddate"
                          no-title
                          color="orange"

                          @input="secondMenu2 = false"
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-title>
            <v-progress-linear
              color="amber"
              indeterminate
              v-if="progress"
            ></v-progress-linear>

            <v-card-text v-if="!progress && income_statement">

              <v-row class="mt-3 rounded-lg" :class="$vuetify.theme.isDark ? 'dark-orange' : 'orange lighten-5'">
                <v-col cols="12" sm="4">
                  <v-card flat>
                    <v-card-text class="text-center">
                      <h3 class="font-weight-black blue--text mt-3"> <span v-if="compareOn">P1:</span>  {{totalIncome| absolute | currency_symbol}}</h3>
                      <h3 class="font-weight-black blue--text mt-3" v-if="compareOn && second_income_statement"> <span >P2:</span>  {{second_totalIncome| absolute | currency_symbol}}</h3>
                      <small>Income</small>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="1">
                  <p class="orange--text font-weight-black text-center display-1 mt-3">-</p>
                </v-col>

                  <v-col cols="12" sm="3">
                  <v-card flat>
                    <v-card-text class="text-center">
                      <h3 class="font-weight-black red--text mt-3"><span v-if="compareOn">P1:</span>{{totalExpense | absolute | currency_symbol}}</h3>
                      <h3 class="font-weight-black red--text mt-3" v-if="compareOn && second_income_statement"><span >P2:</span>{{second_totalExpense | absolute | currency_symbol}}</h3>
                      <small>Expense</small>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="1">
                  <p class="orange--text font-weight-black text-center display-1 mt-3">=</p>
                </v-col>

                  <v-col cols="12" sm="3">
                  <v-card flat>
                    <v-card-text class="text-center">
                      <h3 class="font-weight-black mt-3" :class="net_profit<0 ? 'red--text' : 'green--text'">
                        <span v-if="compareOn">P1:</span>{{net_profit | absolute | currency_symbol}}</h3>

                         <h3 v-if="compareOn && second_income_statement" class="font-weight-black mt-3" :class="second_net_profit<0 ? 'red--text' : 'green--text'">
                        <span>P2:</span>{{second_net_profit | absolute | currency_symbol}}</h3>


                      <small v-if="!compareOn">{{net_profit < 0 ? "Loss" : "Profit"}}</small>

                      <small v-else>Profit</small>

                    </v-card-text>
                  </v-card>
                </v-col>

              </v-row>

              <v-simple-table
                v-for="(account, i) in income_statement.income.subtypes"
                :key="account.id"
                v-if="account.name == 'Revenue'"
                class="mt-5"
              >
                <tbody>
                  <tr>
                    <th style="width: 80%">
                      <u>{{ account.name }}</u>
                    </th>

                    <th
                      style="width: 20%"
                      class="text-right"
                      :colspan="compareOn ? 2 : 1"
                    >
                      {{
                        $store.state.user.current_business.currency
                          .symbol
                      }}
                    </th>
                  </tr>

                  <tr v-if="compareOn">
                    <th style="width: 80%"><u></u></th>
                    <th style="width: 20%" v-show="i < 1" class="text-right">
                      {{ startdate | humanDate }} to {{ enddate | humanDate }}
                    </th>

                    <th
                      style="width: 30%"
                      class="text-right"
                      v-if="compareOn && second_income_statement"
                    >
                      {{ secondStartdate | humanDate }} to
                      {{ secondEnddate | humanDate }}
                    </th>
                  </tr>

                  <tr
                    v-for="(subaccount, i) in account.accounts"
                    :key="subaccount.id"
                    v-if="subaccount.account_name != 'Discounts'"
                    v-show="
                      Number(subaccount.balance) != 0 ||
                      (compareOn &&
                        second_income_statement &&
                        Number(
                          second_income_statement.income.subtypes[0].accounts[i]
                            .balance
                        ) != 0)
                    "
                  >
                    <td class="ml-5 pl-10" width="80%">
                      {{ subaccount.account_name }}
                    </td>

                    <td class="ml-5 text-right" width="20%">

                            {{ subaccount.balance | absolute }}

                    </td>

                    <!--compare side-->

                    <td
                      class="ml-5 text-right"
                      style="width: 30%"
                      v-if="compareOn && second_income_statement"
                    >

                            {{
                              second_income_statement.income.subtypes[0]
                                .accounts[i].balance | absolute
                            }}

                    </td>
                  </tr>

                  <tr
                    v-for="(subaccount, i) in account.accounts"
                    :key="subaccount.id"
                    v-if="subaccount.account_name == 'Discounts'"
                    v-show="
                      Number(subaccount.balance) != 0 ||
                      (compareOn &&
                        second_income_statement &&
                        Number(
                          second_income_statement.income.subtypes[0].accounts[i]
                            .balance
                        ) != 0)
                    "
                  >
                    <td class="ml-5 pl-10" width="80%">
                      Less: {{ subaccount.account_name }}
                    </td>
                    <td class="ml-5 text-right" width="20%">

                            {{ subaccount.balance | absolute }}

                    </td>

                    <!--compare side-->

                    <td
                      class="ml-5 text-right"
                      style="width: 30%"
                      v-if="compareOn && second_income_statement"
                    >

                            {{
                              second_income_statement.income.subtypes[0]
                                .accounts[i].balance | absolute
                            }}

                    </td>
                  </tr>
                </tbody>
              </v-simple-table>

              <v-simple-table class="mt-4">
                <tbody>
                  <tr
                    :class="$vuetify.theme.isDark ? 'dark-orange orange--text' : 'orange lighten-5 orange--text'"
                  >
                    <td style="width: 80%" class="border-bottom">
                      <p class="mt-5" v-if="compareOn"></p>
                      <strong>Total Revenue</strong>
                    </td>

                    <td
                      style="width: 20%"
                      class="border-top border-bottom text-right"
                    >
                      <strong>
                        <p v-if="compareOn"></p>
                        {{
                          get_total_revenue(income_statement.income.subtypes)
                            | absolute
                        }}
                      </strong>
                    </td>

                    <!--                  compare side-->

                    <td
                      style="width: 30%"
                      class="border-top border-bottom text-right"
                      v-if="compareOn && second_income_statement"
                    >
                      <strong>
                        <compare-percentage-component
                          :compare-value="
                            get_total_revenue(
                              second_income_statement.income.subtypes
                            )
                          "
                          :main-value="
                            get_total_revenue(income_statement.income.subtypes)
                          "
                        ></compare-percentage-component>

                        {{
                          get_total_revenue(
                            second_income_statement.income.subtypes
                          ) | absolute
                        }}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>

              <v-simple-table
                v-for="account in income_statement.expense.subtypes"
                :key="account.id"
                v-if="account.name == 'Cost of Sale'"
              >
                <tbody>
                  <tr>
                    <th style="width: 80%">
                      <u>{{ account.name }}</u>
                    </th>
                    <th style="width: 20%"></th>
                  </tr>

                  <tr
                    v-for="(subaccount, i) in account.accounts"
                    :key="subaccount.id"
                    v-show="
                      Number(subaccount.balance) != 0 ||
                      (compareOn &&
                        second_income_statement &&
                        Number(
                          second_income_statement.expense.subtypes[0].accounts[
                            i
                          ].balance
                        ) != 0)
                    "
                  >
                    <td class="ml-5 pl-10" width="80%">
                      {{ subaccount.account_name }}
                    </td>

                    <td class="ml-5 text-right" width="20%">

                            {{ subaccount.balance | absolute }}

                    </td>

                    <!--                  compare side-->

                    <td
                      class="ml-5 text-right"
                      style="width: 30%"
                      v-if="compareOn && second_income_statement"
                    >

                            {{
                              second_income_statement.expense.subtypes[0]
                                .accounts[i].balance | absolute
                            }}

                    </td>
                  </tr>
                </tbody>
              </v-simple-table>

              <v-simple-table class="mt-4">
                <tbody>
                  <tr
                    :class="$vuetify.theme.isDark ? 'dark-orange orange--text' : 'orange lighten-5 orange--text'"
                  >
                    <td style="width: 80%" class="border-bottom">
                      <p class="mt-5" v-if="compareOn"></p>
                      <strong>Cost Of Sale</strong>
                    </td>

                    <td
                      style="width: 20%"
                      class="border-top border-bottom text-right"
                    >
                      <strong>
                        <p v-if="compareOn"></p>
                        {{
                          get_total_cost(income_statement.expense.subtypes)
                            | absolute
                        }}
                      </strong>
                    </td>

                    <!--                  compare side-->

                    <td
                      style="width: 30%"
                      class="border-top border-bottom text-right"
                      v-if="compareOn && second_income_statement"
                    >
                      <strong>
                        <compare-percentage-component
                          :compare-value="
                            get_total_cost(
                              second_income_statement.expense.subtypes
                            )
                          "
                          :main-value="
                            get_total_cost(income_statement.expense.subtypes)
                          "
                        ></compare-percentage-component>
                        {{
                          get_total_cost(
                            second_income_statement.expense.subtypes
                          ) | absolute
                        }}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>

              <v-simple-table>
                <tbody>
                  <tr
                    :class="$vuetify.theme.isDark ? 'dark-orange orange--text' : 'orange lighten-5 orange--text'"
                  >
                    <th class="border-bottom border-top" width="80%">
                      <p class="mt-5" v-if="compareOn"></p>
                      <h3>Gross Profit</h3>
                    </th>

                    <th class="border-bottom border-top text-right" width="20%">
                      <h3>
                        <p class="mt-5" v-if="compareOn"></p>

                        {{
                          (Number(
                            get_total_revenue(income_statement.income.subtypes)
                          ) -
                            Number(
                              get_total_cost(income_statement.expense.subtypes)
                            ))
                            | absolute
                        }}
                      </h3>
                    </th>

                    <!--                  compare side-->

                    <th
                      class="border-bottom border-top text-right"
                      style="width: 30%"
                      v-if="compareOn && second_income_statement"
                    >
                      <h3 class="pa-0">
                        <compare-percentage-component
                          :compare-value="
                            Number(
                              get_total_revenue(
                                second_income_statement.income.subtypes
                              )
                            ) -
                            Number(
                              get_total_cost(
                                second_income_statement.expense.subtypes
                              )
                            )
                          "
                          :main-value="
                            Number(
                              get_total_revenue(
                                income_statement.income.subtypes
                              )
                            ) -
                            Number(
                              get_total_cost(income_statement.expense.subtypes)
                            )
                          "
                        ></compare-percentage-component>

                        {{
                          (Number(
                            get_total_revenue(
                              second_income_statement.income.subtypes
                            )
                          ) -
                            Number(
                              get_total_cost(
                                second_income_statement.expense.subtypes
                              )
                            ))
                            | absolute
                        }}
                      </h3>
                    </th>
                  </tr>
                </tbody>
              </v-simple-table>

              <v-simple-table
                v-for="(account, i) in income_statement.income.subtypes"
                :key="account.id"
                v-if="account.name != 'Revenue'"
              >
                <tbody>
                  <tr>
                    <th style="width: 80%">
                      <u>{{ account.name }}</u>
                    </th>

                    <th style="width: 20%" class="text-right" v-show="i < 1">
                      {{
                        $store.state.user.current_business.currency
                          .symbol
                      }}
                    </th>

                    <!-- compare side -->

                    <th
                      style="width: 30%"
                      class="text-right"
                      v-show="i < 1"
                      v-if="compareOn && second_income_statement"
                    >
                      {{
                        $store.state.user.current_business.currency
                          .symbol
                      }}
                    </th>
                  </tr>

                  <tr
                    v-for="(subaccount, i) in account.accounts"
                    :key="subaccount.id"
                    v-show="
                      Number(subaccount.balance) != 0 ||
                      (compareOn &&
                        second_income_statement &&
                        Number(
                          second_income_statement.income.subtypes[1].accounts[i]
                            .balance
                        ) != 0)
                    "
                  >
                    <td class="ml-5 pl-10" width="80%">
                      {{ subaccount.account_name }}
                    </td>

                    <td class="ml-5 text-right" width="20%">

                            {{ subaccount.balance | absolute }}

                    </td>

                    <!-- compare side -->

                    <td
                      v-if="compareOn && second_income_statement"
                      class="ml-5 text-right"
                      style="width: 30%"
                    >

                            {{
                              second_income_statement.income.subtypes[1]
                                .accounts[i].balance | absolute
                            }}

                    </td>
                  </tr>
                </tbody>
              </v-simple-table>

              <v-simple-table class="mt-4">
                <tbody>
                  <tr
                    :class="$vuetify.theme.isDark ? 'dark-orange orange--text' : 'orange lighten-5 orange--text'"
                  >
                    <td class="border-bottom" style="width: 80%">
                      <p class="mt-5" v-if="compareOn"></p>
                      <strong>Total Non-operating Income</strong>
                    </td>

                    <td style="width: 20%" class="border-bottom text-right">
                      <p class="mt-5" v-if="compareOn"></p>
                      <strong>
                        {{
                          get_total_noneoperating(
                            income_statement.income.subtypes,
                            false
                          ) | absolute
                        }}
                      </strong>
                    </td>

                    <!--                  compare side-->

                    <td
                      style="width: 30%"
                      class="border-bottom text-right"
                      v-if="compareOn && second_income_statement"
                    >
                      <strong>
                        <compare-percentage-component
                          :compare-value="
                            get_total_noneoperating(
                              second_income_statement.income.subtypes,
                              true
                            )
                          "
                          :main-value="
                            get_total_noneoperating(
                              income_statement.income.subtypes,
                              false
                            )
                          "
                        ></compare-percentage-component>

                        {{
                          get_total_noneoperating(
                            second_income_statement.income.subtypes,
                            true
                          ) | absolute
                        }}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>

              <v-simple-table
                v-for="(account, i) in income_statement.expense.subtypes"
                :key="account.id"
                v-if="account.name != 'Cost of Sale'"
              >
                <tbody>
                  <tr>
                    <th style="width: 80%">
                      <u>{{ account.name }}</u>
                    </th>
                    <th style="width: 20%"></th>
                  </tr>

                  <tr
                    v-for="(subaccount, i) in account.accounts"
                    :key="subaccount.id"
                    v-show="
                      Number(subaccount.balance) != 0 ||
                      (compareOn &&
                        second_income_statement &&
                        Number(
                          second_income_statement.expense.subtypes[1].accounts[
                            i
                          ].balance
                        ) != 0)
                    "
                  >
                    <td class="ml-5 pl-10" width="80%">
                      {{ subaccount.account_name }}
                    </td>

                    <td class="ml-5 text-right" width="20%">

                            {{ subaccount.balance | absolute }}

                    </td>

                    <!--                  compare side-->

                    <td
                      class="ml-5 text-right"
                      width="30%"
                      v-if="compareOn && second_income_statement"
                    >

                            {{
                              second_income_statement.expense.subtypes[1]
                                .accounts[i].balance | absolute
                            }}

                    </td>
                  </tr>
                </tbody>
              </v-simple-table>

              <v-simple-table class="mt-4">
                <tbody>
                  <tr
                    :class="$vuetify.theme.isDark ? 'dark-orange orange--text' : 'orange lighten-5 orange--text'"
                  >
                    <td style="width: 80%" class="border-bottom">
                      <p v-if="compareOn" class="mt-5"></p>

                      <strong>Total Non-Operating Expense</strong>
                    </td>

                    <td style="width: 20%" class="border-bottom text-right">
                      <p class="mt-5" v-if="compareOn"></p>

                      <strong>
                        {{
                          get_totalOE(income_statement.expense.subtypes)
                            | absolute
                        }}
                      </strong>
                    </td>

                    <!--compare side-->

                    <td
                      style="width: 30%"
                      class="border-bottom text-right"
                      v-if="compareOn && second_income_statement"
                    >
                      <strong>
                        <compare-percentage-component
                          :compare-value="
                            get_totalOE(
                              second_income_statement.expense.subtypes
                            )
                          "
                          :main-value="
                            get_totalOE(income_statement.expense.subtypes)
                          "
                        ></compare-percentage-component>

                        {{
                          get_totalOE(second_income_statement.expense.subtypes)
                            | absolute
                        }}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>

              <v-simple-table class="mt-4">
                <tbody>
                  <tr
                    :class="$vuetify.theme.isDark ? 'dark-orange orange--text' : 'orange lighten-5 orange--text'"
                  >
                    <th style="width: 80%" class="border-bottom border-top">
                      <p v-if="compareOn" class="mt-5"></p>

                      <h3 class="font-weight-black">
                        Net
                        {{ net_profit >= 0 ? "Profit" : "Loss" }}
                      </h3>
                    </th>

                    <th
                      style="width: 20%"
                      class="border-top border-bottom text-right"
                    >
                      <p v-if="compareOn" class="mt-5"></p>
                      <h3 class="font-weight-black">
                        {{ net_profit | absolute }}
                      </h3>
                    </th>

                    <!--compare with -->

                    <th
                      style="width: 30%"
                      class="border-top border-bottom text-right"
                      v-if="compareOn && second_income_statement"
                    >
                      <h3 class="font-weight-black">
                        <compare-percentage-component
                          :compare-value="second_net_profit"
                          :main-value="net_profit"
                        ></compare-percentage-component>
                        {{ second_net_profit | absolute }}
                      </h3>
                    </th>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-speed-dial
        v-model="fab"
        bottom
        fixed
        absolute
        right
        direction="top"
        transition="slide-y-reverse-transition"
        style="margin-right: 100px; margin-bottom: 50px"
        v-if="CAN_DOWNLOAD"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="orange" dark fab>
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-share-variant</v-icon>
          </v-btn>
        </template>
        <v-btn
          depressed
          dark
          rounded
          color="blue darken-4"
          @click="open_print()"
        >
          <v-icon>mdi-printer</v-icon>
          Print
        </v-btn>
        <v-btn depressed rounded light color="amber" :href="PDFURL">
          <v-icon>mdi-file-download</v-icon>
          Download PDF
        </v-btn>

        <v-btn depressed rounded dark color="green" :href="ExcelURL">
          <v-icon>mdi-google-spreadsheet</v-icon>
          Download Excel
        </v-btn>
      </v-speed-dial>
    </div>

  </div>
</template>

<script>
import moment from "moment";

import ComparePercentageComponent from "../../components/ComparePercentageComponent";

export default {
  name: "incomestatementComponent",
  components: {
    ComparePercentageComponent,

  },
  data() {
    return {
      tags: [],
      availableTags: [],
      searchTag: "",
      fab: false,
      print_dialog: false,
      date: "",
      progress: true,
      secondProgress: false,
      income_statement: {},
      second_income_statement: null,
      total_non_current_assets: 0,
      header: "For the Period",
      startdate: "",
      enddate: "",

      secondStartdate: "",
      secondEnddate: "",

      menu1: false,
      menu2: false,

      secondMenu1: false,
      secondMenu2: false,
      realised_gains_balance: 0,
      compareOn: false,
    };
  },
  computed: {

    second_totalIncome(){

    return   this.get_total_noneoperating(this.second_income_statement.income.subtypes,false) + this. get_total_revenue(this.second_income_statement.income.subtypes);

    },
  totalIncome(){

    return   this.get_total_noneoperating(this.income_statement.income.subtypes,false) + this.get_total_revenue(this.income_statement.income.subtypes);

    },

    second_totalExpense(){
     return  this.get_totalOE(this.second_income_statement.expense.subtypes) + this.get_total_cost(this.second_income_statement.expense.subtypes)
    },

    totalExpense(){
     return  this.get_totalOE(this.income_statement.expense.subtypes) + this.get_total_cost(this.income_statement.expense.subtypes)
    },


    PDFURL() {
      if (this.compareOn && this.second_income_statement) {
        return (
          this.$store.state.baseURL +
          "/" +
          "downloadincomestatementPDF/?bid=" +
          this.$store.state.user.current_business.enc_id +
          "&startdate=" +
          this.startdate +
          "&enddate=" +
          this.enddate +
          "&tag=" +
          this.tags.toString() +
          "&secondstartdate=" +
          this.secondStartdate +
          "&secondenddate=" +
          this.secondEnddate
        );
      }

      return (
        this.$store.state.baseURL +
        "/" +
        "downloadincomestatementPDF/?bid=" +
        this.$store.state.user.current_business.enc_id +
        "&startdate=" +
        this.startdate +
        "&enddate=" +
        this.enddate +
        "&tag=" +
        this.tags.toString()
      );
    },

    ExcelURL() {
      if (this.compareOn && this.second_income_statement) {
        return (
          this.$store.state.baseURL +
          "/" +
          "downloadincomestatementspreadsheat/?bid=" +
          this.$store.state.user.current_business.enc_id +
          "&startdate=" +
          this.startdate +
          "&enddate=" +
          this.enddate +
          "&tag=" +
          this.tags.toString() +
          "&secondstartdate=" +
          this.secondStartdate +
          "&secondenddate=" +
          this.secondEnddate
        );
      }

      return (
        this.$store.state.baseURL +
        "/" +
        "downloadincomestatementspreadsheat/?bid=" +
        this.$store.state.user.current_business.enc_id +
        "&startdate=" +
        this.startdate +
        "&enddate=" +
        this.enddate +
        "&tag=" +
        this.tags.toString()
      );
    },
    baseUrl() {
      return this.$store.state.baseURL;
    },

    net_profit() {
      return (
        Number(
          this.get_total_noneoperating(this.income_statement.income.subtypes)
        ) +
        (Number(this.get_total_revenue(this.income_statement.income.subtypes)) -
          Number(this.get_total_cost(this.income_statement.expense.subtypes)) -
          Number(this.get_totalOE(this.income_statement.expense.subtypes)))
      );
    },
    second_net_profit() {
      if (!this.second_income_statement) {
        return 0;
      }

      return (
        Number(
          this.get_total_noneoperating(
            this.second_income_statement.income.subtypes,
            true
          )
        ) +
        (Number(
          this.get_total_revenue(this.second_income_statement.income.subtypes)
        ) -
          Number(
            this.get_total_cost(this.second_income_statement.expense.subtypes)
          ) -
          Number(
            this.get_totalOE(this.second_income_statement.expense.subtypes)
          ))
      );
    },

    CAN_VIEW() {
      if (this.$store.state.user.is_admin === 1) {
        return true;
      } else
        return (
          this.$store.state.user.components[15].roles.view === 1
        );
    },
    CAN_DOWNLOAD() {
      if (this.$store.state.user.is_admin === 1) {
        return true;
      } else
        return (
          this.$store.state.user.components[15].roles.download === 1
        );
    },
  },
  methods: {
    getAvailableTags() {
      axios.get("/api/tags").then(({ data }) => {
        this.availableTags = data
          .map((tag) => (tag.name ? tag.name.split(",") : []))
          .flat();
      });
    },
    applyTagFilter() {
      if (this.tags.length === 0) return;
      this.get_incomestatement();
    },
    setCurrentDate() {
      this.startdate = moment().format("YYYY-MM-DD");
      this.startdate = this.startdate.substring(0, 8) + "01";
      this.enddate = moment().format("YYYY-MM-DD");
    },
    resetToDefault() {
      if (this.tags.length === 0) {
        this.setCurrentDate();
        this.get_incomestatement();
      }
    },
    print_sheet() {
      window.print();
    },

    get_total_cost(accounts) {
      let sum = 0;
      accounts.forEach((account) => {
        if (account.name === "Cost of Sale") {
          account.accounts.forEach((ac) => {
            sum += ac.balance;
          });
        }
      });
      return sum;
    },
    get_cost(accounts) {
      return accounts.filter((account) => {
        return account.name === "Cost of Sale" ? account.accounts : null;
      });
    },
    open_print() {
      if (this.compareOn && this.second_income_statement) {
        window.open(
          this.$store.state.baseUrl +
            "/" +
            "previewincomestatement/?bid=" +
            this.$store.state.user.current_business.enc_id +
            "&startdate=" +
            this.startdate +
            "&enddate=" +
            this.enddate +
            "&tag=" +
            this.tags.toString() +
            "&secondstartdate=" +
            this.secondStartdate +
            "&secondenddate=" +
            this.secondEnddate
        );
      } else {
        window.open(
          this.$store.state.baseUrl +
            "/" +
            "previewincomestatement/?bid=" +
            this.$store.state.user.current_business.enc_id +
            "&startdate=" +
            this.startdate +
            "&enddate=" +
            this.enddate +
            "&tag=" +
            this.tags.toString()
        );
      }
    },
    get_profit(income, expense) {
      return Number(this.get_total(income)) - Number(this.get_total(expense));
    },

    get_total(accounts) {
      let sum = 0;
      accounts.forEach((account) => {
        sum += Number(account.balance);
      });

      return sum;
    },

    get_totalOE(accounts) {
      let sum = 0;
      accounts.forEach((account) => {
        if (account.name == "Operating Expenses") {
          sum += Number(account.balance);
        }
      });

      return sum;
    },
    get_total_revenue(accounts) {
      let sum = 0;
      accounts.forEach((account) => {
        if (account.name == "Revenue") {
          sum += Number(account.balance);
        }
      });

      return sum;
    },

    get_total_noneoperating(accounts, second = false) {
      let sum = 0;
      accounts.forEach((account) => {
        if (account.name != "Revenue") {
          sum += Number(account.balance);
        }
      });

      if (second) {
        return sum + 0;
      }

      return sum + this.$store.state.unrealisedGains;
    },

    get_incomestatement() {
      if (this.$store.state.user.is_admin != 1) {
        if (this.$store.state.user.components[15].roles.view == 0) {
          return false;
        }
      }

      this.$store.state.unrealisedGains = 0;

      this.progress = true;
      const hasTags = this.tags.length > 0;

      const url = hasTags
        ? `/api/getincomestatement/?startdate=${this.startdate}&enddate=${
            this.enddate
          }&tag=${this.tags.toString()}`
        : `/api/getincomestatement/?startdate=${this.startdate}&enddate=${this.enddate}`;

      axios.get(url).then((res) => {
        this.progress = false;
        this.income_statement = res.data;
      });
    },

    get_secondIncomestatement() {
      if (this.$store.state.user.is_admin != 1) {
        if (this.$store.state.user.components[15].roles.view == 0) {
          return false;
        }
      }

      if (!this.secondEnddate || !this.secondStartdate) {
        return;
      }

      this.$store.state.unrealisedGains = 0;

      this.secondProgress = true;
      const hasTags = this.tags.length > 0;

      const url = hasTags
        ? `/api/getincomestatement/?startdate=${this.secondStartdate}&enddate=${
            this.secondEnddate
          }&tag=${this.tags.toString()}`
        : `/api/getincomestatement/?startdate=${this.secondStartdate}&enddate=${this.secondEnddate}`;
      axios.get(url).then((res) => {
        this.secondProgress = false;
        this.second_income_statement = res.data;
      });
    },

    //this function would skip all unwanted accounts in search of the those under non-current assets
    get_total_equity(accounts) {
      let sum = 0;
      accounts.forEach((account) => {
        sum += Number(account.balance);
      });
      return sum;
    },

    get_total_balanceNone(accounts) {
      let sum = 0;
      accounts.forEach((account) => {
        if (
          account.name == "Property, Plant and Equipments" ||
          account.name == "Depreciation & Amortization" ||
          account.name == "Depreciation & Amortization" ||
          account.name == "Other Fixed Assets"
        ) {
          sum += Number(account.balance);
        }
      });

      return sum;
    },

    get_total_liabilitiesNone(accounts) {
      let sum = 0;
      accounts.forEach((account) => {
        if (
          account.name == "Loans" ||
          account.name == "Other Non-Current Liabilities"
        ) {
          sum += Number(account.balance);
        }
      });

      return sum;
    },
    get_total_liabilities(accounts) {
      let sum = 0;
      accounts.forEach((account) => {
        if (
          account.name != "Loans" &&
          account.name != "Other Non-Current Liabilities"
        ) {
          sum += Number(account.balance);
        }
      });

      return sum;
    },

    //this function would skip all unwanted accounts in search of the those under current assets
    get_total_balance(accounts) {
      let sum = 0;
      accounts.forEach((account) => {
        if (
          account.name != "Property, Plant and Equipments" &&
          account.name != "Depreciation & Amortization" &&
          account.name != "Depreciation & Amortization" &&
          account.name != "Other Fixed Assets"
        )
          sum += Number(account.balance);
      });

      return sum;
    },
  },
  mounted() {
    this.getAvailableTags();
    this.setCurrentDate();
    this.get_incomestatement();
  },
};
</script>

<style scoped></style>
