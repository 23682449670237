<template>
  <v-dialog  scrollable
             v-model="showOverlay"
             color="orange">
    <v-card flat>
      <v-card-title>
        <v-btn @click="$router.back()" text color="orange">
          <v-icon>mdi-arrow-left</v-icon>
          Back
        </v-btn>
        <v-spacer></v-spacer>
        Create a sale order
      </v-card-title>
      <v-card-text>
        <v-stepper v-model="orderStep" elevation="0" class="pa-0">
          <v-stepper-content step="1" class="pa-0">
            <v-row dense>
              <v-col cols="12" sm="5" offset="1">

                <v-card
                        :class="{'dark-orange':$vuetify.theme.isDark, 'orange lighten-5':!$vuetify.theme.isDark}" flat>
                  <v-card-text>
                    <v-expansion-panels flat class="rounded-xl">
                      <v-expansion-panel @change="ToggledCustomer">
                        <v-expansion-panel-header>Customer info.</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-card flat>
                            <v-card-title></v-card-title>
                            <v-card-text>
                              <v-form>
                                <v-row dense>
                                  <v-col cols="12">
                                    <v-text-field dense clearable v-model="order.customer.name" color="orange" outlined
                                                  placeholder="Customer Name"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6">

                                    <v-text-field dense clearable v-model="order.customer.email" color="orange"
                                                  type="email"
                                                  outlined
                                                  placeholder="Customer Email"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6">
                                    <v-text-field dense clearable v-model="order.customer.phone_number" color="orange"
                                                  type="tel"
                                                  outlined
                                                  placeholder="Customer Phone Number"></v-text-field>
                                  </v-col>

                                  <v-col cols="12">
                                    <v-textarea color="orange" auto-grow outlined dense rows="1" v-model="order.customer.address"
                                                label="address"></v-textarea>
                                  </v-col>


                                </v-row>


                              </v-form>
                            </v-card-text>
                          </v-card>

                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>


                    <div style="overflow:auto!important;"
                         :class="{'short-item-hieght':customerToggle,'long-item-hieght':!customerToggle}"
                         v-if="order.items.length" class="pb-5">
                      <v-card
                          flat v-for="(item,index) in order.items"
                          :key="item.id"
                          class="mt-2 cart-item">
                        <v-card-text>
                          <v-card-title>
                            {{ item.name }}

                            <v-spacer></v-spacer>
                            <v-menu :close-on-content-click="false" max-width="400">
                              <template v-slot:activator="{on}">

                                <v-btn v-on="on" text color="orange" small>
                                  <v-badge overlap dot color="green" :value="item.applied_taxes.length>0">Add taxes
                                    <v-icon small color="orange">mdi-chevron-down</v-icon>
                                  </v-badge>
                                </v-btn>


                              </template>
                              <v-card>
                                <v-card-text>
                                  <v-autocomplete
                                      deletable-chips
                                      item-color="orange"
                                      item-text="display_name"
                                      item-value="id"
                                      :items="businessTaxes"
                                      v-model="item.applied_taxes"
                                      multiple
                                      outlined
                                      chips
                                      color="orange"
                                      @change="applyChanges(index)"
                                  ></v-autocomplete>
                                </v-card-text>
                              </v-card>
                            </v-menu>

                            <v-btn @click="removeItem(index)" small icon color="red">
                              <v-icon small>mdi-delete-outline</v-icon>
                            </v-btn>
                          </v-card-title>
                          <v-row dense>
                            <v-col cols="12" sm="2">
                              <v-avatar size="80" rounded="lg">
                                <v-img :src="item.photo"></v-img>
                              </v-avatar>
                            </v-col>
                            <v-col cols="12" sm="10">
                              <v-row dense>
                                <v-col cols="12" sm="6">
                                  <v-text-field
                                      label="QTY"
                                      @input="applyChanges(index)"
                                      color="orange"
                                      v-model="item.quantity"
                                      dense
                                      outlined
                                      placeholder="Quantity"
                                      type="number"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                  <v-text-field
                                      @input="applyChanges(index)"
                                      color="orange"
                                      v-model="item.unit_price"
                                      :prefix="$store.state.user.current_business.currency.code"
                                      dense
                                      outlined
                                      placeholder="Price"
                                      type="number"
                                      label="Price"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-text-field color="orange"
                                                v-model="item.discount"
                                                :prefix="$store.state.user.current_business.currency.code"
                                                dense
                                                outlined
                                                @input="applyChanges(index)"
                                                placeholder="Discount"
                                                label="Discount"
                                                type="number"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="8">
                                  <v-textarea v-model="item.description" dense rows="1" color="orange" outlined
                                              auto-grow
                                              placeholder="Description"></v-textarea>
                                </v-col>

                              </v-row>

                            </v-col>

                          </v-row>
                        </v-card-text>
                      </v-card>
                    </div>
                    <no-record-component
                        v-else
                        title="No order items"
                        description="Add order items here"></no-record-component>


                  </v-card-text>
                  <v-card-actions>
                    <v-menu top max-width="580px" transition="slide-y-transition" :close-on-content-click="false">
                      <template v-slot:activator="{on}">
                        <v-btn v-on="on" depressed block color="orange" dark rounded x-large>Add item
                          <v-icon>mdi-chevron-up</v-icon>
                        </v-btn>

                      </template>
                      <orderitem-selector
                          @selected="addNewItem"
                          :items="saleItems"></orderitem-selector>
                    </v-menu>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="12" sm="5" >
                <v-card outlined>
                  <v-card-text>
                    <p class="text-center">
                      <v-icon color="orange" size="60">mdi-account-outline</v-icon>
                    </p>
                    <v-list>
                      <v-list-item dense class="border-bottom">
                        <v-list-item-content>
                          <v-list-item-title>{{ order.customer.name ? order.customer.name : "N/A" }}</v-list-item-title>
                          <v-list-item-subtitle>Customer Name</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item dense class="border-bottom">
                        <v-list-item-content>
                          <v-list-item-title>{{
                              order.customer.email ? order.customer.email : "N/A"
                            }}
                          </v-list-item-title>
                          <v-list-item-subtitle>Customer Email</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item dense class="border-bottom">
                        <v-list-item-content>
                          <v-list-item-title>{{ order.customer.phone_number ? order.customer.phone_number : "N/A" }}
                          </v-list-item-title>
                          <v-list-item-subtitle>Customer Phone Number</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item dense class="border-bottom">
                        <v-list-item-content>
                          <v-list-item-title>{{ order.customer.address ? order.customer.address : "N/A" }}
                          </v-list-item-title>
                          <v-list-item-subtitle>Customer Address</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                    </v-list>
                  </v-card-text>

                  <v-card-text>
                    <v-simple-table dense fixed-header>
                      <thead>
                      <tr>
                        <th>
                          Item
                        </th>
                        <th class="text-center">
                          QTY
                        </th>
                        <th class="text-right">
                          Price
                        </th>
                        <th class="text-right">
                          Amount
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="item in order.items" :key="item.id">
                        <td>
                          <strong> {{ item.name }}</strong>
                          <small class="d-block grey--text">{{ item.description }}</small>
                        </td>
                        <td class="text-center">
                          {{ item.quantity }}
                        </td>
                        <td class="text-right">
                          {{ item.unit_price | toMoney | currency_symbol }}
                        </td>

                        <td class="text-right">
                          {{ item.amount | toMoney | currency_symbol }}
                        </td>


                      </tr>
                      <tr class="mt-3">
                        <td colspan="2"></td>

                        <td colspan="2"
                            :class="{'grey lighten-4':!$vuetify.theme.isDark, 'black':$vuetify.theme.isDark}">
                          <h2 class="text-right font-weight-light mt-3">
                            Total:{{ totalAmount | toMoney | currency_symbol }}</h2>
                        </td>
                      </tr>
                      <tr class="mt-3">
                        <td colspan="2"></td>

                        <td colspan="2"
                            :class="{'grey lighten-4':!$vuetify.theme.isDark, 'black':$vuetify.theme.isDark}">
                          <h2 class="text-right font-weight-light mt-3">
                            Dicount:{{ totalDiscount | toMoney | currency_symbol }}</h2>
                        </td>
                      </tr>
                      <tr class="mt-3">
                        <td colspan="2"></td>

                        <td colspan="2"
                            :class="{'grey lighten-4':!$vuetify.theme.isDark, 'black':$vuetify.theme.isDark}">
                          <h2 class="text-right font-weight-light mt-3">Net
                            Amount:{{ netAmount | toMoney | currency_symbol }}</h2>
                        </td>
                      </tr>

                      <tr class="mt-3">
                        <td colspan="2"></td>

                        <td colspan="2"
                            :class="{'grey lighten-4':!$vuetify.theme.isDark, 'black':$vuetify.theme.isDark}">
                          <h2 class="text-right font-weight-light mt-3">Taxes:{{
                              totalTaxAmount | toMoney | currency_symbol
                            }}</h2>
                        </td>
                      </tr>

                      <tr class="mt-3">
                        <td colspan="2"></td>

                        <td colspan="2"
                            :class="{'grey lighten-4':!$vuetify.theme.isDark, 'black':$vuetify.theme.isDark}">
                          <h2 class="text-right font-weight-black mt-3 orange--text">Amount
                            Due:{{ totalAmountDue | toMoney | currency_symbol }}</h2>
                        </td>
                      </tr>

                      </tbody>
                    </v-simple-table>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn :disabled="order.items.length<1"
                           @click="saveOrderDialog=true;setDefaultAccount();" block outlined rounded depressed
                           color="orange" dark x-large>Create Order
                    </v-btn>
                  </v-card-actions>
                </v-card>


              </v-col>

            </v-row>
          </v-stepper-content>
          <v-stepper-content step="2">
            <p>
              <v-btn @click="orderStep=1" outlined color="orange" x-large>Create New</v-btn>
            </p>
            <order-preview-component
                :order="createdOrder"
                v-if="orderStep>1"></order-preview-component>
          </v-stepper-content>
        </v-stepper>

      </v-card-text>
      <v-card-actions></v-card-actions>
    </v-card>

    <v-dialog :persistent="saving" scrollable width="500" v-model="saveOrderDialog">
      <v-card>
        <v-card-title>
<!--          <v-btn @click="payStep=1" v-if="payStep>1" icon>-->
<!--            <v-icon>mdi-arrow-left</v-icon>-->
<!--          </v-btn>-->
          Save Order
        </v-card-title>
        <v-card-text>
          <v-stepper v-model="payStep" elevation="0">
            <v-stepper-content step="1">
              <v-card flat>
                <v-card-text>
                  <h2 class="text-center font-weight-light text-h5 ma-3">How did they pay?</h2>
                  <v-btn @click="payStep=2" dark x-large block rounded depressed color="orange" class="ma-2"> I Received
                    Cash
                    <v-icon>mdi-cash</v-icon>
                  </v-btn>
                  <v-btn block dark x-large rounded depressed outlined color="orange" class="ma-2">Mobile Money Payment
                    <v-icon>mdi-cellphone-message</v-icon>
                  </v-btn>

<!--                  <v-btn :loading="saving" @click="saveOrder" block dark x-large rounded depressed text color="orange" class="ma-2">-->
<!--                    Save without payment-->
<!--                  </v-btn>-->


                </v-card-text>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-card>
                <v-card-text v-if="order && order.items.length">
                  <h2 class="text-center">Total Amount:{{ totalAmountDue | toMoney | currency_symbol }}</h2>
                  <p class="text-center">Record Payments for this order</p>
                  <p class="text-center red--text" v-if="payments.length && totalPayments!==totalAmountDue">Sum of your
                    payments is not the same
                    as the amount due
                    <v-btn text color="green" small @click="setSettingsAccount">Reset</v-btn>
                  </p>
                  <v-form ref="paymentForm">

                    <v-simple-table>
                      <tr v-for="(payment,index) in payments" :key="index">
                        <td>
                          <v-autocomplete :rules="[store.state.validationRules.required]" item-color="orange"
                                          color="orange" class="mt-2" item-value="id" label="Account"
                                          dense item-text="account_name" outlined :items="accounts"
                                          v-model="payment.account_id"></v-autocomplete>
                        </td>
                        <td>

                          <v-text-field :rules="[store.state.validationRules.required]"
                                        :prefix="store.state.user.current_business.currency.code" color="orange"
                                        class="mt-2" outlined type="number" dense label="Amount"
                                        v-model="payment.amount"></v-text-field>
                        </td>
                        <td>
                          <v-btn @click="removePayment(index)" icon small color="red">
                            <v-icon small>mdi-delete-outline</v-icon>
                          </v-btn>

                        </td>
                      </tr>
                    </v-simple-table>
                  </v-form>

                  <v-btn :disabled="payments.length>2" block text small color="orange" class="mt-3"
                         @click="addPaymentMethod">Add payment method
                  </v-btn>
                </v-card-text>
                <v-card-actions>
                  <v-btn :loading="saving" @click="saveOrder"
                         rounded
                         color="orange" dark depressed x-large block>Save Order
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </v-stepper>

        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import {defineComponent} from 'vue'
import NoRecordComponent from "../components/noRecordComponent.vue";
import OrderitemSelector from "../components/OrderitemSelector.vue";
import store from "../store";
import OrderPreviewComponent from "../components/OrderPreviewComponent.vue";

export default defineComponent({
  name: "NewOrder",
  components: {OrderPreviewComponent, OrderitemSelector, NoRecordComponent},
  data() {
    return {
      customerToggle: false,
      showOverlay: true,
      saveOrderDialog: false,
      orderStep: 1,
      payments: [],
      payStep: 2,
      accounts: [],
      createdOrder: null,
      saving: false,
      order: {
        customer: {
          name: "",
          email: "",
          phone_number: "",
          address: ""
        },
        items: []
      },
      saleItems: []
    }
  },
  computed: {
    store() {
      return store
    },
    businessTaxes() {
      return this.$store.state.user.current_business.taxes;
    },

    windowWidth() {
      return window.innerWidth - 200;
    },
    windowHeight() {
      return window.innerHeight - 50
    },
    totalAmount() {

      let sum = 0;

      this.order.items.forEach(item => {
        sum += item.amount
      })

      return sum;

    },
    totalDiscount() {

      let sum = 0;

      this.order.items.forEach(item => {
        sum += Number(item.discount)
      })

      return sum;

    },

    netAmount() {
      return this.totalAmount - this.totalDiscount;
    },
    totalTaxAmount() {
      let sum = 0;

      this.order.items.forEach(item => {
        sum += Number(item.tax_amount);
      })

      return sum;

    },


    totalAmountDue() {

      return this.netAmount + this.totalTaxAmount;

    },
    settings() {
      return this.$store.state.settings;
    },
    totalPayments() {
      let sum = 0;
      this.payments.forEach(payment => {
        sum += Number(payment.amount);
      })

      return sum;
    }

  },
  methods: {
    removePayment(index) {

      this.payments.splice(index, 1);

    },

    addPaymentMethod() {

      if (this.payments.length < 1) {
        this.payments.push({
          account_id: null,
          amount: this.totalAmountDue,
        });

      } else {
        this.payments.push({
          account_id: null,
          amount: 0,
        });
      }

    },
    getAccounts() {
      axios.get("/api/pos/bank-accounts").then((res) => {
        this.accounts = res.data;
      });
    },
    saveOrder() {

      if (this.$refs.paymentForm.validate()) {
        this.saving = true;
        const data = {
          total_amount: this.totalAmount,
          discount_amount: this.totalDiscount,
          amount_due: this.totalAmountDue,
          customer_name: this.order.customer.name,
          customer_phone_number: this.order.customer.phone_number,
          customer_email: this.order.customer.email,
          customer_address: this.order.customer.address,
          payments: this.payments,
          tax_amount: this.totalTaxAmount,
          items:this.order.items

        };

        axios.post("/api/pos/orders", data)
            .then(res => {

              this.saving = false;
              this.saveOrderDialog = false;

              this.createdOrder = res.data;

              const NewURL = "/orders/new?order_id=" + this.createdOrder.uuid;
              this.$router.push({path: NewURL});

              this.orderStep = 2;
            })
            .catch(() => {

              this.saving = false;
            })

      }

    },

    ToggledCustomer() {

      this.customerToggle = !this.customerToggle;

    },
    get_tax_amount(taxes, amount) {
      let sum = 0;

      if (taxes.length) {

        const net_amount = amount;

        taxes.forEach(tax => {

          if (tax.type.toLowerCase() === "flat") {
            sum += (Number(tax.rate) / 100) * Number(net_amount);
          } else {
            const subTax =
                tax.sub_rate > 0
                    ? (Number(tax.sub_rate) / 100) * Number(net_amount)
                    : 0;

            const compoundAmount = net_amount + Number(subTax);

            const totaltax = Number(tax.rate / 100) * Number(compoundAmount);

            sum += Number(subTax) + Number(totaltax);
          }
        });

      }


      return sum;

    },

    addNewItem(item) {


      const existingIndex = this.order.items.findIndex(orderItem => {
        return orderItem.product_id == item.product_id;
      })


      if (existingIndex >= 0) {
        this.order.items[existingIndex].quantity++;
        this.applyChanges(existingIndex);

      } else {
        this.order.items.push(item);

        const newIndex = this.order.items.length - 1;
        this.applyChanges(newIndex);

      }


    },

    applyChanges(index) {

      this.order.items[index].amount = this.order.items[index].unit_price * this.order.items[index].quantity;

      const netAmount = this.order.items[index].amount - this.order.items[index].discount;

      this.order.items[index].net_amount = netAmount;

      const businessTaxes = this.businessTaxes.filter(t => this.order.items[index].applied_taxes && this.order.items[index].applied_taxes.includes(t.id));

      const taxAmount =this.get_tax_amount(businessTaxes, netAmount);

      this.order.items[index].tax_amount = taxAmount;
      this.order.items[index].amount_due=netAmount-taxAmount;

    },

    removeItem(index) {
      this.order.items.splice(index, 1);
    },

    getSaleItems() {
      axios.get("/api/getinvoiceitems")
          .then(res => {
            this.saleItems = res.data;

          })
    },
    setSettingsAccount() {
      this.payments = [{
        account_id: this.settings.account_id,
        amount: this.totalAmountDue
      }];
    },
    setDefaultAccount() {
      if (this.settings && this.settings.account_id) {

        this.setDefaultAccount();
      }

    }
  },
  mounted() {

    this.getSaleItems();
    this.getAccounts();
    const queryUuid = this.$route.query.order_id;

    if (queryUuid){
      this.orderStep=2;
    }

  }
})
</script>


<style>

.short-item-hieght {


}

.long-item-hieght {



}

.dark-orange {
  background-color: #FF98000D !important;
}

.cart-item {
  transition: 0.25s ease-in-out;
}

.cart-item:hover {
  border: 1px solid #ff9800 !important;
  background-color: rgba(255, 152, 0, 0.05);
}

</style>